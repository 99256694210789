import { unmask } from 'remask';
import { removeMask } from '@libs/utils/masks/cpf';

import {
  SignupPayloadProps,
  SigninPayloadProps,
  KbaRegisterPayloadProps,
  MFASigninPayloadProps,
  GenerateCodeRequestProps,
  CheckCodeRequestProps,
  GenerateCodePayload,
  CheckCodePayload,
} from './types';

export const transformFindCustomer = (data: string) => ({
  userDocument: unmask(data),
});

export const transformSingupPayload = (
  data: SignupPayloadProps,
  additional: Record<string, string>
) => ({
  celular: data.cellphone ? unmask(data.cellphone) : '',
  dataNascimento: data.birthdate,
  documento: unmask(data.document),
  email: data.email,
  password: data.password,
  sexo: data.gender,
  nome: data.name,
  termsOfUse: true,
  termsOfUseVersion: 0,
  newsletter: data.newsletter ? data.newsletter : false,
  parameters: {
    kbaVersion: 1,
    questionType: data.parameters?.questionType,
    answer: data.parameters?.answer,
    captcha: data.captcha,
    "g-recaptcha-response": data.parameters?.["g-recaptcha-response"],
    "g-recaptcha-action": data.parameters?.["g-recaptcha-action"],
    ...additional,
  },
});

export const transformSigninPayload = (data: SigninPayloadProps) => ({
  captcha: null,
  document: data.document,
  password: data.password,
  username: data.document,
});

export const transformMFASigninPayload = (data: MFASigninPayloadProps) => ({
  captcha: null,
  document: data.document,
  password: data.password,
  username: data.document,
  code: data?.verificationCode,
  answers: data?.answers,
  token: data?.token,
  type: data.type,
  version: 1,
});

export const transformKbaRegisterPayload = (data: KbaRegisterPayloadProps) => ({
  customerId: unmask(data.document),
  version: 1,
	"g-recaptcha-response": data["g-recaptcha-response"],
	"g-recaptcha-action": data["g-recaptcha-action"]
});

export const transformGenerateCodePayload = (data: GenerateCodePayload): GenerateCodeRequestProps => {
  const payload = {
    notification_type: data.type,
    document: removeMask(data.document),
  } as GenerateCodeRequestProps;

  if (data.type === 'sms') {
    payload.phone = data?.phone;
  }

  if (data.type === 'email') {
    payload.email = data?.email;
    payload.iem = data?.isEmailMFA
  }

  return payload;
};

export const transformCheckCodePayload = (data: CheckCodePayload): CheckCodeRequestProps => {
  const payload = {
    code: data.code,
    document: removeMask(data.document),
  } as CheckCodeRequestProps;

  if (data.isEmailMFA) {
    payload.email = data?.email;
    payload.iem = data?.isEmailMFA;
  }

  return payload;
};
